import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { CryptoService } from "../crypto.service";

@Injectable() 
export class PayloadDecryptInterceptor implements HttpInterceptor {
    
    constructor(private encDec: CryptoService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(event => {
                if(event instanceof HttpResponse) {
                    if(event.body instanceof Blob) return event.body;
                    
                    if(event.status === 200 || event.status === 201) {
                        try {
                            if('data' in event.body) {
                                const type = request.headers.get('type');
                                event.body.data = this.decryptData(event.body.data, type, request.url);
                            }
                        } catch (error) {}
                    }
                }
                return event;
            })
        )
    }

    decryptData(value: string, type: any, url: string) {
        let decryptedValue = null;
        try {
            decryptedValue = this.encDec.decrypt(value, type);
            try {
                if(!url.includes('file/save')) {
                    decryptedValue = JSON.parse(decryptedValue)
                }
            } catch (error) {
                console.log(error);
            }
        } catch (error) {
            decryptedValue = value;
        }
        return decryptedValue;
    }
}
