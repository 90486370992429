import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    getMonthYear(monthYearString?: string) {
        const dateObj = monthYearString ? new Date(monthYearString) : new Date();
        let month: any = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();
        month = month < 10 ?  `0${month}` : month;
        return { month, year };
    }

    getLastDateOfPrevMonth() {
        const date = new Date();
        const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        const formattedDate = TodayDateFormat(lastDay);
        return formattedDate;
    }
}


export function ChangeDateFormat(date: string) {
    const incomingDate = date.split('-');
    const year = incomingDate[2];
    let day = incomingDate[0].toString();
    let month = incomingDate[1].toString();

    if (day.length <= 1) {
        day = '0' + day;
    }

    if (month.length <= 1) {
        month = '0' + month;
    }

    return year + '-' + month + '-' + day;
}

export function ToUppercase(value: string) {
    if (value === null || value === undefined) { return null; }
    if (typeof value === 'number') { return value; }
    return value.trim().toUpperCase();
}

export function ToString(value: any) {
    if (CheckValidity(value)) { return null; }
    return value.toString().trim();
}

export function CheckValidity(value: any): boolean {
    if (value !== null && value !== undefined) {
        value = value.toString().trim();
    }

    return (value === null || value === undefined || value.trim() === '' || value === 'null') ? true : false;
}

export function TodayDateFormat(date?: Date) {
    if(!date) date = new Date()

    let dd = date.getDate().toString();
    if (dd.length < 2) {
        dd = '0' + dd;
    }

    let mm = (date.getMonth() + 1).toString();
    if (mm.length < 2) {
        mm = '0' + mm;
    }
    const yyyy = date.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
}

