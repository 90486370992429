import { NgClass } from '@angular/common';
import { Component, signal } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';

interface MenuModel {
  label: string;
  img?: string;
  url?: string;
  showSubmenu?: boolean;
  submenu?: MenuModel[];
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterModule,
    NgClass
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  hideSidebar = signal(true);
  hideMenu = signal(true);

  sidebarOptions: MenuModel[] = [
    // { 
    //   label: 'Warehouse Details',
    //   img: 'warehouse.svg',
    //   url: 'warehouse',
    //   showSubmenu: false,
    //   submenu: [
    //     { label: 'Warehouse List', url: 'list' },
    //     { label: 'Add Warehouse', url: 'add' },
    //   ]
    // },
    // { 
    //   label: 'Stock Statement',
    //   img: 'Update Stock Statement.svg',
    //   url: 'stock-statement',
    //   showSubmenu: false,
    //   submenu: [
    //     { label: 'Update Stock Statement', url: 'update' },
    //     { label: 'Stock Statement History', url: 'history' },
    //   ]
    // },
    // {
    //   label: 'Update Book Debt',
    //   img: 'book-debt.svg',
    //   showSubmenu: false,
    //   url: 'book-debt',
    //   submenu: [
    //     { label: 'Update Book Debt', url: 'update' },
    //     { label: 'Book Debt History', url: 'history' },
    //   ]
    // },
    // { 
    //   label: 'Stock Statement Summary',
    //   img: 'Stock Details.svg',
    //   url: 'stock-summary',
    //   showSubmenu: false,
    //   submenu: [
    //     { label: 'Stock Statement Summary', url: 'request' },
    //     { label: 'Summary History', url: 'history' },
    //   ]
    // },
    // { label: 'Update GST & URC', img: 'Update GST URC.svg', url: 'update-gst-urc' },
    // { 
    //   label: 'Solvency',
    //   img: 'Apply for Solvency.svg',
    //   url: 'solvency',
    //   showSubmenu: false,
    //   submenu: [
    //     { label: 'Apply For Solvency', url: 'apply' },
    //     { label: 'Solvency History', url: 'history' },
    //   ]
    // },
    // { 
    //   label: 'Insurance',
    //   img: 'Apply for Insurance.svg',
    //   url: 'insurance',
    //   showSubmenu: false,
    //   submenu: [
    //     { label: 'Update Insurance', url: 'update' },
    //     { label: 'Apply For Insurance', url: 'apply-new' }
    //   ]
    // },
    // { 
    //   label: 'Offers For Your Customers',
    //   img: 'Offers For Your Customers.svg',
    //   url: 'offer',
    //   showSubmenu: false,
    //   submenu: [
    //     { label: 'Create Offer', url: 'create' },
    //     { label: 'Offers Dashboard', url: 'dashboard' }
    //   ]
    // },
    // { 
    //   label: 'Feedback',
    //   img: 'Feedback.svg',
    //   url: 'feedback', 
    //   showSubmenu: false,
    //   submenu: [
    //     { label: 'Create Feedback', url: 'create' },
    //     { label: 'Feedback Dashboard', url: 'dashboard' }
    //   ]
    // },
  ]

  constructor(
    private router: Router
  ) {}

  setSideBar(status: boolean) {
    if(this.checkSideBarFromUrlStatus()) {
      status = false;
    }
    this.hideSidebar.set(!status);
  }

  checkSideBarFromUrlStatus() {
    const hideSidebarOnUrls = ['/user-details', '/company-details', '/loan-details', '/redirect'];

    if(hideSidebarOnUrls.includes(this.router.url) || this.router.url.startsWith('/home') 
    || this.router.url.startsWith('/feedback/fill-response') || this.router.url.startsWith('/offer/view')) {
      this.hideSidebar.set(true);
      this.hideMenu.set(true);
      return true;
    }
    return false;
  }

  onMenuClick(menu: MenuModel) {
    if(menu.submenu?.length > 0) {
      menu.showSubmenu = !menu.showSubmenu;
    }
  }

  logoutUser() {}
}
