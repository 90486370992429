import { ApplicationConfig, provideExperimentalZonelessChangeDetection, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { JsonPipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './services/interceptors/jwt.interceptor';
import { PayloadDecryptInterceptor } from './services/interceptors/payload-decrypt-interceptor';
import { PayloadEncryptInterceptor } from './services/interceptors/payload-encrypt-interceptor';

import { ENVIRONMENT } from '@thillais/environment';
import { environment } from 'src/environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    JsonPipe,
    provideExperimentalZonelessChangeDetection(),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PayloadEncryptInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PayloadDecryptInterceptor, multi: true },
    { provide: ENVIRONMENT, useValue: environment }
  ]
};
