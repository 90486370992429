import { HttpHeaders } from "@angular/common/http";

const BASE_URL = `https://muladhanam.in/`;
const from = 'MULADHANAM_MAIN_UAT';

export const environment = {
    env: 'stag',
    from,
    BASE_URL: `${BASE_URL}api/`,
    MS_URL: BASE_URL,
    MD_URL: `${BASE_URL}mahabank/`,
    secretKey_1: 'MGY5REc4TTZBfHNrcHxlVg==',
    secretKey_2: 'MmRYZmxrMkJXM2VoNlQzYQ==',
    HTTP_OPTIONS: {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'type': '1'
        })
    },
    HTTP_OPTIONS_UTIL: {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'from': from,
            'type': '2'
        })
    },
    HTTP_OPTIONS_MULTIPART: {
        headers: new HttpHeaders({
            'noEnc': 'true',
            'from': from,
            'type': '2'
        })
    },
    HTTP_OPTIONS_NO_TOKEN: {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'skipToken': 'true',
            'type': '1'
        })
    },
    HTTP_OPTIONS_NO_TOKEN_NO_ENC: {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'skipToken': 'true',
            'type': '1',
            'noEnc': 'true',
        })
    },
    HTTP_OPTIONS_DOWNLOAD: {
        responseType: 'blob' as 'json',
        observe: 'response',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'from': from,
          'type': '2'
        })
    }
}