import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CredentialService } from '../credential.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private credentials: CredentialService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(request.headers.get('skipToken')) {
            const newHeaders = request.headers.delete('skipToken');
            const newRequest = request.clone({ headers: newHeaders });
            return next.handle(newRequest);
        }
        return next.handle(this.handleRequest(request));
    }

    handleRequest(request: HttpRequest<any>): HttpRequest<any> {
        const TOKEN = this.credentials.Token();

        if (TOKEN) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${TOKEN}`
                 }
            });
        }

        return request;
    }
}