import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";

type EncryptionType = '1' | '2';

@Injectable({
    providedIn: 'root'
})
export class CryptoService {
    
    encrypt(value: string, type: EncryptionType = '1') {
        if(!value) return value;
        const { secretKey_1, secretKey_2 } = environment;
        const pwd = type === '1' ? secretKey_1 : secretKey_2;
        return CryptoJS.AES.encrypt(value, window.atob(pwd)).toString();
    }

    decrypt(value: string, type: EncryptionType = '1') {
        if(!value) return value;
        const { secretKey_1, secretKey_2 } = environment;
        const pwd = type === '1' ? secretKey_1 : secretKey_2;
        console.log(window.atob(pwd));
        const dec = CryptoJS.AES.decrypt(value, window.atob(pwd)).toString(CryptoJS.enc.Utf8);
        return dec;
    }

    encryptAsPayload(data: any, type: EncryptionType = '1') {
        if(typeof data !== 'string') data = JSON.stringify(data)
        return { value: this.encrypt(data, type) }
    }

    encryptUsingPass(value: string, password: string) {
        if(!value) return value;
        return CryptoJS.AES.encrypt(value, password).toString();
    }
    
}
