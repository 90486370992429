<header class="w-100 top-bar d-flex justify-content-between py-4">
    <div class="d-flex align-center">
        @if (hideSidebar() && !hideMenu()) {
            <a class="br-5 p-8 bg-blue text-white ml-16" href="javascript:void(0)" (click)="setSideBar(true)">
                <i class="fas fa-bars"></i>
            </a>
        }
        
        <img class="mx-16" src="assets/images/logo/muladhanam.svg" style="width: 200px;" loading="lazy" alt="Canara Bank">
    </div>
</header>

<section class="row">
    @if (!hideSidebar()) {
        <div class="col-md-4 col-lg-3 col-xl-2 sidebar-height">
            <nav id="sidebar" class="sidebar-wrapper bg-blue sidebar-height p-relative">
                <ul class="sidebar-menu pb-8 pl-8 pt-16">
                    @for (option of sidebarOptions; track $index) {
                    <li class="sidebar-dropdown mb-16">
                        <a href="javascript:void(0)" class="d-flex align-center w-100 p-relative pb-8 pl-8"
                            (click)="onMenuClick(option)" [routerLink]="option.url">
                            <img style="width: 16px;" src="assets/images/sidebar/{{option.img}}" [alt]="option.label">
                            <span class="ml-8 fs-14 fw-semibold" routerLinkActive="text-orange">{{option.label}}</span>
                        </a>
        
                        <ul class="sidebar-submenu" style="margin-left: 50px;" [class.d-none]="!option.showSubmenu">
                            @for (item of option.submenu; track $index) {
                            <li class="fs-13 mb-12" routerLinkActive="text-orange"
                                [routerLink]="item.url ? [option.url, item.url] : [option.url]">
                                <a href="javascript:void(0)" class="d-flex align-center w-100 p-relative p-0"
                                    routerLinkActive="text-orange">
                                    {{item.label}}
                                </a>
                            </li>
                            }
        
                        </ul>
                    </li>
                    }
                </ul>
            </nav>
        </div>
    }
   

    <div [ngClass]="hideSidebar() ? 'col-xl-12' : 'col-md-8 col-lg-9 col-xl-10'" class="sidebar-height main-content">
        <div class="p-8">
            <router-outlet></router-outlet>
        </div>
    </div>
</section>
  
