import { Injectable, signal, WritableSignal } from '@angular/core';



@Injectable({
  providedIn: 'root',
})
export class CredentialService {

  // private userData = signal<UserDataModel>(null);
  // readonly UserData = this.userData.asReadonly();
  // setUserData = (userData: UserDataModel) => {
  //   const { USER_PROFILE, COMPANY, WAREHOUSE, LOAN, TOKEN } = userData;
  //   this.setToken(TOKEN);
  //   this.setData(this.userProfile, USER_PROFILE);
  //   this.setData(this.company, COMPANY);
  //   this.setData(this.loan, LOAN);
  //   this.userData.set(userData);
  // };

  // private panResponse = signal<PanResponse>(null);
  // readonly PanResponse = this.panResponse.asReadonly();
  // setPanResponse = (panResponse: PanResponse) => this.panResponse.set(panResponse);

  private token = signal<string>(null);
  readonly Token = this.token.asReadonly();
  setToken = (token: string) => this.token.set(token);
  updateToken = (token: string) => this.token.update(t => token);

  // private userProfile = signal<UserProfileModel>(null);
  // readonly UserProfile = this.userProfile.asReadonly();
  // setUserProfile = (userProfile: UserProfileModel) => this.setData(this.userProfile, userProfile);

  // private company = signal<CompanyModel[]>([]);
  // readonly Company = this.company.asReadonly();
  // updateCompany = (company: CompanyModel) => this.updateData(this.company, company);

  // private loan = signal<LoanDataModel[]>([]);
  // readonly Loan = this.loan.asReadonly();
  // updateLoan = (loan: LoanDataModel) => this.updateData(this.loan, loan);

  private setData = <T>(dataArr: WritableSignal<T[] | T>, data: T[] | T) => dataArr.set(data);
  private updateData = <T>(dataArr: WritableSignal<T[]>, data: T) => dataArr.update(d => [...d, data]);

}
