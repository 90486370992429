import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CheckValidity } from "../utils.service";
import { CryptoService } from "../crypto.service";

@Injectable() 
export class PayloadEncryptInterceptor implements HttpInterceptor {
    constructor(
        private encDec: CryptoService
    ) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { method, body } = request;
        if(CheckValidity(body) || request.headers.get('noEnc')) return next.handle(request);
        
        if((method === 'POST' || method === 'PUT' || method === 'PATCH') && body) {
            const type: any = request.headers.get('type');
            const payload = this.encDec.encryptAsPayload(body, type);
            request = request.clone({ body: payload })
        }

        return next.handle(request);
    }
}
